.mt-3px {
  margin-top: 3px !important; }

.p-5px {
  padding: 5px; }

.p-20px {
  padding: 20px; }

.r-0px {
  right: 0 !important; }

.ml-0 {
  margin-left: 0; }

.mr-0 {
  margin-right: 0; }

.ml-20px {
  margin-left: 20px !important; }

.margin-bottom--10px {
  margin-bottom: 10px; }

@media (max-width: 767px) {
  .unmarg-xs {
    margin: 0 !important; } }

.bg--azzurro {
  background: #4e92df; }
  .bg--azzurro ul li a {
    color: #fff; }

.bg--green {
  background: #48a62c !important;
  color: #fff !important; }
  .bg--green a {
    color: #fff !important; }
  .bg--green ul li a {
    color: #fff !important; }

.bg--yellow {
  background: #bdb23c !important;
  color: #fff !important; }
  .bg--yellow a {
    color: #fff !important; }
  .bg--yellow ul li a {
    color: #fff !important; }

.bg--yellow2 {
  background: #f4d279 !important;
  color: #fff !important; }
  .bg--yellow2 a {
    color: #fff !important; }
  .bg--yellow2 ul li a {
    color: #fff !important; }

.bg--violet {
  background: #ac8ab4 !important;
  color: #fff !important; }
  .bg--violet a {
    color: #fff !important; }
  .bg--violet ul li a {
    color: #fff !important; }

.bg--orange {
  background: #eeaf4d !important;
  color: #fff !important; }
  .bg--orange a {
    color: #fff !important; }
  .bg--orange ul li a {
    color: #fff !important; }

.bg--blue {
  background: #305781 !important;
  color: #fff !important; }
  .bg--blue a {
    color: #fff !important; }
  .bg--blue ul li a {
    color: #fff !important; }

.bg--brown {
  background: #BB9753 !important;
  color: #fff !important; }

.text-azzurro {
  color: #4e92df !important; }
  .text-azzurro span {
    color: #4e92df !important; }

.text-violet {
  color: #ac8ab4 !important; }
  .text-violet span {
    color: #ac8ab4 !important; }

.text-orange {
  color: #eeaf4d !important; }
  .text-orange span {
    color: #eeaf4d !important; }

.text-blue {
  color: #305781 !important; }
  .text-blue span {
    color: #305781 !important; }

.text-green {
  color: #48a62c !important; }
  .text-green span {
    color: #48a62c !important; }

.text-yellow {
  color: #bdb23c !important; }
  .text-yellow span {
    color: #bdb23c !important; }

.text-grey {
  color: #666 !important; }
  .text-grey span {
    color: #666 !important; }

.text-white {
  color: #fff !important; }

.zindex-99 {
  z-index: 99 !important; }

.type--bold {
  font-weight: bold !important; }

.text-no-decoration {
  text-decoration: none !important; }

.display--inline-block {
  display: inline-block; }

.text--intro p {
  font-size: 1.35714286em;
  line-height: 1.68421053em;
  color: #808080; }

.main-container ul {
  list-style-type: disc;
  padding-left: 18px; }
  .main-container ul.slides {
    list-style-type: none;
    padding-left: 0; }

.list-unstyled {
  list-style-type: none !important; }

.masonry-filter-holder:not(.masonry-filters--horizontal) .masonry__filters ul {
  list-style-type: none;
  padding-left: 0; }

@media (max-width: 767px) {
  h4 {
    margin-top: 1.06842105263158em;
    margin-bottom: 1.06842105263158em; } }

.bg--green .menu-horizontal > li > span:hover,
.bg--green .menu-horizontal > li > a:focus,
.bg--green .menu-horizontal > li > a:active,
.bg--green .menu-horizontal > li > a:hover {
  color: #255517 !important; }

.bg--yellow .menu-horizontal > li > span:hover,
.bg--yellow .menu-horizontal > li > a:focus,
.bg--yellow .menu-horizontal > li > a:active,
.bg--yellow .menu-horizontal > li > a:hover {
  color: #706923 !important; }

.bg--violet .menu-horizontal > li > span:hover,
.bg--violet .menu-horizontal > li > a:focus,
.bg--violet .menu-horizontal > li > a:active,
.bg--violet .menu-horizontal > li > a:hover {
  color: #7b5484 !important; }

.bg--orange .menu-horizontal > li > span:hover,
.bg--orange .menu-horizontal > li > a:focus,
.bg--orange .menu-horizontal > li > a:active,
.bg--orange .menu-horizontal > li > a:hover {
  color: #c27e13 !important; }

.bg--blue .menu-horizontal > li > span:hover,
.bg--blue .menu-horizontal > li > a:focus,
.bg--blue .menu-horizontal > li > a:active,
.bg--blue .menu-horizontal > li > a:hover {
  color: #142537 !important; }

.default-menu {
  margin-bottom: 0;
  margin-right: 30px;
  top: 19px !important; }
  .default-menu li span,
  .default-menu li a {
    text-transform: none;
    color: #305781; }
  .default-menu li.dropdown .dropdown__container {
    top: 15px; }
    .default-menu li.dropdown .dropdown__container .menu-vertical li span,
    .default-menu li.dropdown .dropdown__container .menu-vertical li a {
      color: #305781; }

.logo-mobile {
  position: absolute; }

#top-bar ul li a {
  font-weight: 400; }
  #top-bar ul li a i {
    font-size: 10px;
    margin-right: 5px; }

#top-bar.bg--azzurro ul li a:focus, #top-bar.bg--azzurro ul li a:active, #top-bar.bg--azzurro ul li a:hover {
  color: #17477d; }

@media (max-width: 767px) {
  #top-bar .info-main-school li {
    display: block;
    margin: 0 0 10px 0; } }

.bar {
  padding: 12px; }
  .bar .logo {
    margin: 0; }
  @media (max-width: 767px) {
    .bar .logo {
      max-height: 50px; } }

#menu-scuole .loghi-scuole {
  top: 23px; }
  #menu-scuole .loghi-scuole li {
    margin-right: 8px; }
    #menu-scuole .loghi-scuole li a:hover, #menu-scuole .loghi-scuole li a:focus, #menu-scuole .loghi-scuole li a:active {
      opacity: .5; }

#menu-scuole .bar__module .btn {
  top: 19px; }

#menu-scuole .bar__module .header-logo img {
  max-height: 90px; }

@media (max-width: 767px) {
  #menu-scuole .loghi-scuole {
    top: 0; }
  #menu-scuole .bar__module .btn {
    top: 0; } }

.bar-main .menu-horizontal li a {
  font-size: 14px; }

.bar-main .menu-horizontal .menu-vertical li a {
  color: #666 !important; }

.hamburger-toggle-mobile {
  position: absolute;
  right: 10px;
  top: 6px; }

.notification.side-menu .side-menu__module {
  padding-top: 3.785714em; }
  .notification.side-menu .side-menu__module .menu-vertical li {
    margin-bottom: 0.5em; }
    .notification.side-menu .side-menu__module .menu-vertical li span,
    .notification.side-menu .side-menu__module .menu-vertical li a {
      font-size: 18px;
      line-height: 18px;
      color: #666; }

.notification.side-menu .notification-close-cross {
  background: #4e92df;
  line-height: 100%;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin-top: 0;
  padding: 0px;
  text-align: center;
  padding-top: 12px;
  right: 10px;
  top: 10px; }
  .notification.side-menu .notification-close-cross:before {
    font-size: 40px;
    color: #fff;
    font-weight: 600;
    display: block;
    position: relative;
    margin-top: 2px; }

field-error.btn--white {
  background-color: #fff; }
  field-error.btn--white span {
    color: #000 !important; }

.btn-white-o {
  border-color: #fff !important;
  color: #fff; }

.btn-green-o {
  border-color: #48a62c !important;
  color: #48a62c; }
  .btn-green-o:focus, .btn-green-o:active, .btn-green-o:hover, .btn-green-o.active {
    background: #48a62c;
    color: #fff; }
    .btn-green-o:focus .btn__text, .btn-green-o:active .btn__text, .btn-green-o:hover .btn__text, .btn-green-o.active .btn__text {
      color: #fff; }
  .btn-green-o .btn__text {
    color: #48a62c; }

.btn-yellow-o {
  border-color: #bdb23c !important;
  color: #bdb23c; }
  .btn-yellow-o:focus, .btn-yellow-o:active, .btn-yellow-o:hover, .btn-yellow-o.active {
    background: #bdb23c;
    color: #fff; }
    .btn-yellow-o:focus .btn__text, .btn-yellow-o:active .btn__text, .btn-yellow-o:hover .btn__text, .btn-yellow-o.active .btn__text {
      color: #fff; }
  .btn-yellow-o .btn__text {
    color: #bdb23c; }

.btn--white-o {
  border-color: #fff !important;
  color: #fff; }

.btn-violet-o {
  border-color: #ac8ab4 !important;
  color: #ac8ab4; }
  .btn-violet-o:focus, .btn-violet-o:active, .btn-violet-o:hover, .btn-violet-o.active {
    background: #ac8ab4;
    color: #fff; }
    .btn-violet-o:focus .btn__text, .btn-violet-o:active .btn__text, .btn-violet-o:hover .btn__text, .btn-violet-o.active .btn__text {
      color: #fff; }
  .btn-violet-o .btn__text {
    color: #ac8ab4; }

.btn-orange-o {
  border-color: #eeaf4d !important;
  color: #eeaf4d; }
  .btn-orange-o:focus, .btn-orange-o:active, .btn-orange-o:hover, .btn-orange-o.active {
    background: #eeaf4d;
    color: #fff; }
    .btn-orange-o:focus .btn__text, .btn-orange-o:active .btn__text, .btn-orange-o:hover .btn__text, .btn-orange-o.active .btn__text {
      color: #fff; }
  .btn-orange-o .btn__text {
    color: #eeaf4d; }

.btn-blue-o {
  border-color: #305781 !important;
  color: #305781; }
  .btn-blue-o:focus, .btn-blue-o:active, .btn-blue-o:hover, .btn-blue-o.active {
    background: #305781;
    color: #fff; }
    .btn-blue-o:focus .btn__text, .btn-blue-o:active .btn__text, .btn-blue-o:hover .btn__text, .btn-blue-o.active .btn__text {
      color: #fff; }
  .btn-blue-o .btn__text {
    color: #305781; }

.btn-no-radius {
  border-radius: 0; }

.btn-circle {
  border-radius: 50%; }

.btn-radius-15 {
  border-radius: 15px; }

.btn-radius-6 {
  border-radius: 6px; }

.btn-white {
  background: #fff;
  color: #000 !important; }

.btn--llg {
  padding: 20px 0; }

.btn--azzurro4 {
  background: #48CEE0;
  border-color: #48CEE0 !important;
  color: #fff !important; }
  .btn--azzurro4 .btn__text {
    color: #fff !important; }

.btn--green {
  background: #48a62c;
  border-color: #48a62c !important; }

.btn--yellow {
  background: #bdb23c;
  border-color: #bdb23c !important; }

.btn--violet {
  background: #ac8ab4;
  border-color: #ac8ab4 !important; }

.btn--orange {
  background: #eeaf4d;
  border-color: #eeaf4d !important; }

.btn--blue {
  background: #305781;
  border-color: #305781 !important; }

.btn--yellow2 {
  background: #f4d279;
  border-color: #f4d279 !important; }

.btn-azzurro-o {
  border: 1px solid #4e92df !important;
  color: #4e92df;
  text-decoration: none; }

@media (max-width: 767px) {
  .btn-block {
    margin-bottom: 10px; } }

.btn.text-green span {
  color: #48a62c; }

.btn.text-green:focus span, .btn.text-green:active span, .btn.text-green:hover span {
  color: #fff !important; }

.form-email-1 {
  /* do not group these rules */ }
  .form-email-1 textarea,
  .form-email-1 input {
    font-size: 12px;
    background: transparent;
    border: 0;
    border-radius: 0;
    border-bottom: 1px solid #fff;
    color: #fff;
    padding: 5px 0; }
    .form-email-1 textarea:focus,
    .form-email-1 input:focus {
      border-color: #fff; }
  .form-email-1 textarea {
    border: 1px solid #fff;
    padding: 5px; }
  .form-email-1 *::-webkit-input-placeholder {
    color: #fff !important; }
  .form-email-1 *:-moz-placeholder {
    /* FF 4-18 */
    color: #fff !important; }
  .form-email-1 *::-moz-placeholder {
    /* FF 19+ */
    color: #fff !important; }
  .form-email-1 *:-ms-input-placeholder {
    /* IE 10+ */
    color: #fff !important; }

.border-azzurro {
  border: 1px solid #4e92df; }

@media (max-width: 767px) {
  .info-form {
    margin-top: 50px; }
    .info-form p {
      display: block; } }

.picker__select--month,
.picker__select--year {
  padding: 5px;
  line-height: 20px;
  height: 32px;
  width: auto !important;
  display: inline-block;
  min-width: 60px; }

select.field-error {
  border-color: #EBA8A8; }

form .checkbox-privacy {
  position: relative;
  display: inline-flex; }
  form .checkbox-privacy iframe {
    margin-top: 3px;
    display: inline-block; }
    form .checkbox-privacy iframe.iubenda-ibadge {
      min-height: 0 !important; }

table th,
table td {
  font-size: 0.8em;
  line-height: 1.4em; }

@media only screen and (max-width: 800px) {
  /* Force table to not be like tables anymore */
  table,
  thead,
  tbody,
  th,
  td,
  tr {
    display: block; }
  /* Hide table headers (but not display: none;, for accessibility) */
  thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px; }
  tr {
    border: 1px solid #ccc; }
  td {
    /* Behave  like a "row" */
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 50% !important;
    white-space: normal;
    text-align: left; }
  td:before {
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    top: 10px;
    left: 6px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
    text-align: left;
    font-weight: bold; }
  /*
	Label the data
	*/
  td:before {
    content: attr(data-title); } }

footer {
  background: #305781; }
  footer ul:not(.list-inline):not(.slides) > li {
    line-height: 180% !important; }

@media (max-width: 767px) {
  #legal ul li {
    text-align: center; } }

.bg-main-footer-contact {
  background: #4E92DF;
  color: #fff; }

.bg-main-footer {
  background: #527EAD;
  color: #fff; }

.icon-border {
  border: 1px solid #fff; }

.icon-circle-o {
  border-radius: 50%; }

.breadcrumbs {
  margin-bottom: 0; }
  .breadcrumbs > li:not(:last-child) {
    margin-bottom: 0; }

h1.title-page {
  margin-bottom: 10px; }

.video-play-icon-o {
  background: transparent !important;
  border: 1px solid #fff !important;
  color: #fff !important; }
  .video-play-icon-o:before {
    border-color: transparent transparent transparent #fff !important; }

.slides .flickity-prev-next-button {
  background: none;
  border: 1px solid #fff;
  border-radius: 50%;
  width: 40px;
  height: 40px; }
  .slides .flickity-prev-next-button.previous {
    left: 10px; }
  .slides .flickity-prev-next-button.next {
    right: 10px; }

@media (max-width: 767px) {
  .slides .flickity-viewport {
    height: 85vh !important; }
    .slides .flickity-viewport .flickity-slider .imagebg {
      height: 100% !important;
      display: flex;
      align-items: center; } }

@media (max-width: 767px) {
  .slider.slider-height-auto .flickity-viewport {
    min-height: 230px;
    height: auto !important; } }

.wizard > .steps > ul > li {
  width: 18%; }
  .wizard > .steps > ul > li > a .number {
    display: none; }

@media (max-width: 767px) {
  .wizard .content {
    min-height: 32em; } }

@media (max-width: 767px) {
  section.cover {
    margin-top: 0 !important; } }

.feature-3 h4 {
  display: block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.feature-3 p {
  max-width: none !important;
  display: block;
  /* Fallback for non-webkit */
  display: -webkit-box;
  max-width: 100%;
  height: 75px;
  /* Fallback for non-webkit */
  margin: 0 auto 20px 0;
  line-height: 24px;
  max-height: 80px;
  min-height: 70px;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis; }

@media (max-width: 767px) {
  .feature-3.boxed h4 {
    margin-top: 0 !important; }
  .feature-3.boxed p {
    min-height: 0 !important;
    height: auto !important; } }

.feature:hover.feature-8.feature-orange span.label {
  background-color: #eeaf4d; }

.feature:hover.feature-8.feature-green span.label {
  background-color: #48a62c; }

.feature:hover.feature-8.feature-yellow span.label {
  background-color: #bdb23c; }

.feature .feature__body .label-tag {
  margin-top: 5px; }

.process-1 .process__item.close .process__content {
  display: none; }

.process-1 .process__item.close:after {
  background-color: #fff; }

.process-1 .process__item > h4 {
  cursor: pointer; }
  .process-1 .process__item > h4:hover, .process-1 .process__item > h4:active, .process-1 .process__item > h4:focus {
    color: #999999; }

.process-1 .process__item .process__content {
  display: block; }

.process-1 .process__item:after {
  background-color: #4a90e2; }

.process-1 .process__item:hover:after, .process-1 .process__item:focus:after, .process-1 .process__item:active:after {
  background-color: #4a90e2; }

.social-share h4 small {
  display: block;
  width: 270px;
  margin: 0 auto;
  border-top: 1px solid #666;
  margin-top: 12px;
  padding-top: 8px; }

.social-share ul li a {
  background: #666;
  width: 30px;
  height: 30px;
  display: inline-block;
  border-radius: 50%;
  color: #fff;
  padding-top: 3px; }
  .social-share ul li a:hover, .social-share ul li a:focus, .social-share ul li a:active {
    background: #000;
    color: #fff; }

.social-share ul li:not(:last-child) {
  margin-right: 1em; }

@media (max-width: 767px) {
  img {
    margin-bottom: 1.5em; } }

.picker {
  z-index: 999 !important; }
  .picker table th,
  .picker table td {
    padding: 0.52857143em;
    text-align: center; }

@media (min-width: 768px) and (max-width: 991px) {
  .default-menu {
    margin-right: 0;
    text-align: right; }
    .default-menu li {
      margin-left: 6px;
      margin-right: 6px; } }

#main-homepage #box-homepage .box-blue {
  position: absolute;
  top: 15px;
  left: 58%;
  right: 0;
  text-align: center;
  background: #4E92DF;
  padding-top: 10px; }
  #main-homepage #box-homepage .box-blue .w-180px {
    width: 180px; }
  #main-homepage #box-homepage .box-blue .box-dark-blue {
    background: #4281C9;
    padding: 10px 0;
    margin-top: 15px; }

@media (min-width: 1200px) {
  #main-homepage #box-homepage .image-half {
    max-width: 91%;
    max-height: 662px; } }

@media (min-width: 768px) and (max-width: 991px) {
  #main-homepage #box-homepage .image-half {
    width: 85.5%; }
  #main-homepage #box-homepage .box-blue a {
    padding: 0;
    width: 150px !important; }
  #main-homepage .col-md-2 {
    padding-left: 0; }
    #main-homepage .col-md-2 > div {
      margin-bottom: 15px !important;
      padding: 10px 5px 2px 5px !important; } }

@media (min-width: 992px) and (max-width: 1199px) {
  #main-homepage #box-homepage .image-half {
    width: 75%; } }

@media (max-width: 767px) {
  #main-homepage #box-homepage .image-half {
    width: 100%;
    border-radius: 6px; }
  #main-homepage #box-homepage .box-blue {
    position: relative;
    left: 0;
    top: 0;
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 6px; }
    #main-homepage #box-homepage .box-blue .box-dark-blue {
      border-radius: 0 0 6px 6px; }
  #main-homepage .feature-3 {
    margin-bottom: 10px !important; }
    #main-homepage .feature-3 h3,
    #main-homepage .feature-3 p {
      text-align: center !important;
      display: block; } }

@media (max-width: 767px) {
  .row > [class*="col-"]:not(:last-child) {
    margin-bottom: 20px; } }

footer iframe.iubenda-ibadge {
  float: right;
  margin-top: 3px;
  float: left; }
  footer iframe.iubenda-ibadge .iubenda-white {
    margin-left: 10px; }
